


import  "../styles/main.scss";
 
import   logo  from "../assets/menekse-logo.png"
import   flag  from "../assets/tr.svg"
import   mobilelogosrc  from "../assets/menekse-logo.png"
import   slideBg  from "../assets/slider-1.png"
import   closeBars  from "../assets/icons.png"
import   openBars  from "../assets/icons-2.png"
import   round  from "../assets/slider-part.png"
import   sliderPizze  from "../assets/slider-pizza.png"
import   sliderlahmacun  from "../assets/lahmacun.png"
import   homeAbout  from "../assets/home-about.png"
import   loading  from "../assets/restaurant-gif.gif"



import   gallery1  from "../assets/gallery-1.png"
import   gallery2  from "../assets/gallery-2.png"
import   gallery3  from "../assets/gallery-3.png"
import   gallery4  from "../assets/gallery-4.png"
import   gallery5  from "../assets/gallery-5.png"
import   gallery6  from "../assets/gallery-6.png"

const galleryItem1 = document.querySelector('.gallery-1')
const galleryItem2 = document.querySelector('.gallery-2')
const galleryItem3 = document.querySelector('.gallery-3')
const galleryItem4 = document.querySelector('.gallery-4')
const galleryItem5 = document.querySelector('.gallery-5')
const galleryItem6 = document.querySelector('.gallery-6')

if (galleryItem1) galleryItem1.src = gallery1;
if (galleryItem2) galleryItem2.src = gallery2;
if (galleryItem3) galleryItem3.src = gallery3;
if (galleryItem4) galleryItem4.src = gallery4;
if (galleryItem5) galleryItem5.src = gallery5;
if (galleryItem6) galleryItem6.src = gallery6;



import  galleryresize1  from "../assets/gallery-1.png"
import  galleryresize2  from "../assets/gallery-2.png"
import  galleryresize3  from "../assets/gallery-3.png"
import  galleryresize4  from "../assets/gallery-4.png"
import  galleryresize5  from "../assets/gallery-5.png"
import  galleryresize6  from "../assets/gallery-6.png"

const galleryItemResize1 = document.querySelector('.gallery-resize-1')
const galleryItemResize2 = document.querySelector('.gallery-resize-2')
const galleryItemResize3 = document.querySelector('.gallery-resize-3')
const galleryItemResize4 = document.querySelector('.gallery-resize-4')
const galleryItemResize5 = document.querySelector('.gallery-resize-5')
const galleryItemResize6 = document.querySelector('.gallery-resize-6')

if (galleryItemResize1) galleryItemResize1.href = galleryresize1;
if (galleryItemResize2) galleryItemResize2.href = galleryresize2;
if (galleryItemResize3) galleryItemResize3.href = galleryresize3;
if (galleryItemResize4) galleryItemResize4.href = galleryresize4;
if (galleryItemResize5) galleryItemResize5.href = galleryresize5;
if (galleryItemResize6) galleryItemResize6.href = galleryresize6;




import   aboutImg1  from "../assets/about-1.png"
import   aboutImg2  from "../assets/about-2.png"
const aboutItem1 = document.querySelector('.about-1')
const aboutItem2 = document.querySelector('.about-2')
if (aboutItem1) aboutItem1.src = aboutImg1;
if (aboutItem2) aboutItem2.src = aboutImg2;



import   servicesIzgaracesitleri  from "../assets/izgara-cesitleri.png"
import   servicesCocuk  from "../assets/service-cocuk.png"
import   servicesİcecekler  from "../assets/service-icecekler.png"
import   servicesKahvalti  from "../assets/service-kahvalti.png"
import   servicesKebap  from "../assets/service-kebap.png"
import   servicesTatli  from "../assets/service-tatli.png"
import   servicesTava  from "../assets/service-tava.png"
import   servicesYoresel  from "../assets/service-yoresel.png"
const servicesItemIzgaraHome = document.querySelector('.services__Imgİzgara')
const servicesItemKebapHome = document.querySelector('.services__ImagKebap')
const servicesItemYoreselHome = document.querySelector('.services__Imagyoresel')
const servicesItemTavaHome = document.querySelector('.services__Imagtava')
const servicesItemTatliHome = document.querySelector('.services__Imagtatli')
const servicesItemKahvaltiHome = document.querySelector('.services__Imagkahvalti')
const servicesItemİceceklerHome = document.querySelector('.services__Imagicecekler')
const servicesItemCocukHome = document.querySelector('.services__Imagcocuk')
if (servicesItemIzgaraHome) servicesItemIzgaraHome.src = servicesIzgaracesitleri;
if (servicesItemKebapHome) servicesItemKebapHome.src = servicesKebap;
if (servicesItemYoreselHome) servicesItemYoreselHome.src = servicesYoresel;
if (servicesItemTavaHome) servicesItemTavaHome.src = servicesTava;
if (servicesItemTatliHome) servicesItemTatliHome.src = servicesTatli;
if (servicesItemKahvaltiHome) servicesItemKahvaltiHome.src = servicesKahvalti;
if (servicesItemİceceklerHome) servicesItemİceceklerHome.src = servicesİcecekler;
if (servicesItemCocukHome) servicesItemCocukHome.src = servicesCocuk;




import   qrImgizgara  from "../assets/qr-tavuk.png"
import   qrImgcocuk  from "../assets/qr-cocuk.png"
import   qrImgicecek  from "../assets/qr-icecekler.png"
import   qrImgkahvalti  from "../assets/qr-kahvalti.png"
import   qrImgKebap  from "../assets/qr-kebap.png"
import   qrImgtatli  from "../assets/qr-tatli.png"
import   qrImgtava  from "../assets/qr-tava.png"
import   qrImgyoresel  from "../assets/qr-yoresel.png"
const qrItemkebap = document.querySelector('.qr-kebap')
const qrItemizgara = document.querySelector('.qr-izgara')
const qrItemyoresel = document.querySelector('.qr-yoresel')
const qrItemtava = document.querySelector('.qr-tava')
const qrItemtatli = document.querySelector('.qr-tatli')
const qrItemkahvalti = document.querySelector('.qr-kahvalti')
const qrItemicecekler = document.querySelector('.qr-icecekler')
const qrItemcocuk = document.querySelector('.qr-cocuk')
if (qrItemkebap) qrItemkebap.src = qrImgKebap;
if (qrItemizgara) qrItemizgara.src = qrImgizgara;
if (qrItemyoresel) qrItemyoresel.src = qrImgyoresel;
if (qrItemtava) qrItemtava.src = qrImgtava;
if (qrItemtatli) qrItemtatli.src = qrImgtatli;
if (qrItemkahvalti) qrItemkahvalti.src = qrImgkahvalti;
if (qrItemicecekler) qrItemicecekler.src = qrImgicecek;
if (qrItemcocuk) qrItemcocuk.src = qrImgcocuk;



import   detailFistkliKebap  from "../assets/detail-fistikli-kebap.png"
import   detailUrfaKebap  from "../assets/detail-urfa-kebap.png"
import   detailFirinKebap  from "../assets/detail-firin-kebap.png"
import   detailAdanaKebap  from "../assets/detail-adana-kebap.png"
import   detailPatlicanKebap  from "../assets/detail-patlican-kebap.png"

const detailItemurfaKebap = document.querySelector('.detail-urfaKebap')
const detailItemAdanaKebap = document.querySelector('.detail-adanaKebap')
const detailItmPatlicanKebap = document.querySelector('.detail-patlicanKebap')
const detailItemFistikliKebap = document.querySelector('.detail-fistikliKebap')
const detailItemFirinKebab = document.querySelector('.detail-firinKebap')

if (detailItemurfaKebap) detailItemurfaKebap.src = detailUrfaKebap;
if (detailItemAdanaKebap) detailItemAdanaKebap.src = detailAdanaKebap;
if (detailItemFistikliKebap) detailItemFistikliKebap.src = detailFistkliKebap;
if (detailItemFirinKebab) detailItemFirinKebab.src = detailFirinKebap;
if (detailItmPatlicanKebap) detailItmPatlicanKebap.src = detailPatlicanKebap;



import   popImgtavukSis  from "../assets/pop-tavuk-sis.png"
import   popkebapImg  from "../assets/pop-kebap.png"
const popTavukSisItem = document.querySelector('.poppular__imgTavukSis')
const popkebapItem = document.querySelector('.poppular__imgkebap')
if (popTavukSisItem) popTavukSisItem.src = popImgtavukSis;
if (popkebapItem) popkebapItem.src = popkebapImg;


import   chef_1_Img  from "../assets/chef-1.png"
import   chef_2_Img  from "../assets/chef-2.png"
import   chef_3_Img  from "../assets/chef-3.png"
const chefItem1 = document.querySelector('.chef-1')
const chefItem2 = document.querySelector('.chef-2')
const chefItem3 = document.querySelector('.chef-3')
if(chefItem1) {
  chefItem1.src = chef_1_Img
 }
 if(chefItem2) {
  chefItem2.src = chef_2_Img
 }
 if(chefItem3) {
  chefItem3.src = chef_3_Img
 }


import   news_1_Img  from "../assets/izgara-2.png"
import   news_2_Img  from "../assets/adana-kebap-2.png"
import   news_3_Img  from "../assets/news-3.png"
import   news_4_Img  from "../assets/news-4.png"
import   news_5_Img  from "../assets/news-5.png"
import   news_6_Img  from "../assets/news-6.png"

const news_1_Item = document.querySelector('.news-1')
const news_2_Item = document.querySelector('.news-2')
const news_3_Item = document.querySelector('.news-3')
const news_4_Item = document.querySelector('.news-4')
const news_5_Item = document.querySelector('.news-5')
const news_6_Item = document.querySelector('.news-6')

if (news_1_Item) news_1_Item.src = news_1_Img;
if (news_2_Item) news_2_Item.src = news_2_Img;
if (news_3_Item) news_3_Item.src = news_3_Img;
if (news_4_Item) news_4_Item.src = news_4_Img;
if (news_5_Item) news_5_Item.src = news_5_Img;
if (news_6_Item) news_6_Item.src = news_6_Img;






const logoImg = document.querySelector('.sidebar__logo img')
   



const roundItems = document.querySelectorAll('.slider__rond-img')
const sliderproductItems = document.querySelector('.slider__product')
const homeAbutItem = document.querySelector('.image-about')
const sliderLahmacunItem = document.getElementById('slider-2')
const mobileLogo = document.querySelector('.mobile-logo')
const slideBgItems = document.querySelectorAll('.slide-1') 
const closeBarsItem = document.querySelector('.closeBars')
const openBarsItem = document.querySelector('.openBars') 
const flagsrc = document.getElementById('flag')
const loadingItem = document.getElementById('loading')





if (flagsrc) flagsrc.src = flag;
if (mobileLogo) mobileLogo.src = mobilelogosrc;
if (homeAbutItem) homeAbutItem.src = homeAbout;





if (loadingItem) loadingItem.src = loading;
if (logoImg) logoImg.src = logo;
if (sliderLahmacunItem) sliderLahmacunItem.src = sliderlahmacun;
if (closeBarsItem) closeBarsItem.src = closeBars;
if (openBarsItem) openBarsItem.src = openBars;
if (sliderproductItems) sliderproductItems.src = sliderPizze;

 roundItems.forEach((roundItem) => {
  if (roundItem) {
    roundItem.src = round;
  }
});

slideBgItems.forEach((sliderBg) => {
  if (sliderBg) {
    sliderBg.src = slideBg;
  }
});
 

const open = document.getElementById('open');
const close = document.getElementById('close');
const panel = document.getElementById('panel');
const panelMobile = document.querySelector('.sidebar__mobile-menu');
const partMenus = document.querySelectorAll('.sidebar__part-menu');
const plusButtons = document.querySelectorAll('.plus');
const markButtons = document.querySelectorAll('.mark-close');



plusButtons.forEach((plus, index) => {
  plus.addEventListener('click', () => {
    partMenus[index].classList.add('active'); 
    plus.style.display = 'none'; 
    markButtons[index].style.display = 'block'; 
  });
});

markButtons.forEach((mark, index) => {
  mark.addEventListener('click', () => {
    partMenus[index].classList.remove('active'); 
    plusButtons[index].style.display = 'block'; 
    mark.style.display = 'none'; 
  });
});  



const sidebarmobile = document.querySelector('.sidebar__detail');
const mobileMenu = document.querySelector('.sidebar__mobile-menu');
const sidebar__mobilelogo = document.querySelector('.sidebar__mobile-logo');

const close_reservation = document.getElementById('close-reservation');
const reservationModal = document.getElementById('reservationModal');



const mobileOpenButton = document.getElementById('MobileOpen');
const MobileClose = document.getElementById('MobileClose');

mobileOpenButton.addEventListener('click', () => {
  mobileMenu.classList.toggle('open');
  sidebarmobile.style.backgroundColor = 'transparent';
  sidebar__mobilelogo.style.display = 'none';
  mobileOpenButton.style.display = 'none';
  MobileClose.style.display = 'flex';
});

MobileClose.addEventListener('click', () => {
  mobileMenu.classList.remove('open');
  sidebarmobile.style.backgroundColor = 'var(--sidebar)';
  sidebar__mobilelogo.style.display = 'block';
  mobileOpenButton.style.display = 'block';
  MobileClose.style.display = 'none';
});

open.addEventListener('click', () => {
  panel.style.transform = 'scale(1)'; 
  openBarsItem.style.display = 'block';
  closeBarsItem.style.display = 'none';

});

close.addEventListener('click', () => {
  panel.style.transform = 'scale(0)'; 
  openBarsItem.style.display = 'none';
  closeBarsItem.style.display = 'block';

});





var swiper = new Swiper('.swiper-container', {
 
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});




document.addEventListener("DOMContentLoaded", function () {

  var scrollToTopBtn = document.getElementById("scrollToTopBtn");
      var scrolled = false;

      window.addEventListener("scroll", function () {
        if (!scrolled && (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800)) {
          scrollToTopBtn.classList.add("active");
        } else {
          scrollToTopBtn.classList.remove("active");
        }
      });




    document.getElementById("loadingScreen").style.display = "none";
 
 
  var aboutLeft = document.querySelector(".about__left");
  var aboutRight = document.querySelector(".about__right");
  var secitonAnimate = document.querySelector(".section-animate");
  var reservationHome__container = document.querySelector('.reservationHome__container');
  var popular__cards = document.querySelector('.popular__cards');
  var testimonials__item = document.querySelector('.testimonials__animate');
  var  chef__animate = document.querySelector('.chef__animate');
  var  news__animate = document.querySelector('.news .container');
  var  footer__animate = document.querySelector('.footer .container');




 
  
 

  
  window.addEventListener("scroll", function () {
    const reservationHome = document.querySelector('.reservationHome');
    const aboutSection = document.querySelector('.about');
    const servicesSection = document.querySelector('.services');
    const popular = document.querySelector('.popular');
    const chef = document.querySelector('.chef');
    const testimonials = document.querySelector('.testimonials');
    const news = document.querySelector('.news');
    const footer = document.querySelector('.footer');

    function addAnimateClass(element, className) {
      if (element) {
        element.classList.add(className);
        animateCounters(`.${element.className} .count`);
      }
    }
    var windowHeight = window.innerHeight;
    if (reservationHome) {
      if (reservationHome.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(reservationHome__container, "animate");
      }
    }
    if (aboutSection) {
      if (aboutSection.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(aboutLeft, "animate");
        addAnimateClass(aboutRight, "animate");
      }
    }
    if(servicesSection) {
      if (servicesSection.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(secitonAnimate, "animate");
      }
    }
    
    if(popular){
      if (popular.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(popular__cards, "animate");
      }
    }
    
    if(chef){
      if (chef.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(chef__animate, "animate");
      }
    }
    
    if(testimonials){
      if (testimonials.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(testimonials__item, "animate");
      } 
    }
    
    if(news){
      if (news.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(news__animate, "animate");
      }
    }
   
    if(footer){
      if (footer.getBoundingClientRect().top < windowHeight / 1) {
        addAnimateClass(footer__animate, "animate");
      } 
    }
  
  });
});

function animateCounters() {
  $('.count').each(function () {
    var $this = $(this),
      countTo = $this.attr('data-count');
  
    $({ countNum: $this.text() }).animate({
      countNum: countTo
    },
      {
        duration: 8000,
        easing: 'linear',
        step: function () {
          $this.text(Math.floor(this.countNum));
        },
        complete: function () {
          $this.text(this.countNum);
          
        }
      });
  
  });
}
 

const reservations = document.querySelectorAll('.reservation');

reservations.forEach(reservation => {
  reservation.addEventListener('click', () => {
    reservationModal.style.transform = 'scale(1)';
  });
});

close_reservation.addEventListener('click', () => {
  reservationModal.style.transform = 'scale(0)';
});



  
  $(document).ready(function () {
    $('.testimonial-carousel').owlCarousel({
        loop: true,
        margin: 20,
        nav: true,
        dots: true,      
        items: 1,
        autoplay: true,
        autoplayTimeout: 5000,   
        autoplayHoverPause: true
    });

  
});

$(document).ready(function () {
  $('.news-carousel').owlCarousel({
      loop: true,
      margin: 20,
      nav: true,
      dots: true,      
      items: 3,
      autoplay: true,
      autoplayTimeout: 5000,   
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1
        },
        500: {
          items: 2
        },
        900: {
          items: 3
        },
        1000: {
          items: 3
        }
      }
  });


});

  $(document).ready(function () {
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 20,
     
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1
        },
        500: {
          items: 2
        },
        900: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
    }); 
  });
  
 
  
  